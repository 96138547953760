import { Vue, Component, Watch } from "vue-property-decorator";
import { IRequestDatatable } from "@/models";
import { RequestDatatable } from "@/components";

@Component({
    components: {
        RequestDatatable,
    },
    metaInfo: {
        title: "Manage requests",
    },
})

export default class RequestsManage extends Vue {

    public loading : boolean = false;
    public includeClosedRejected : boolean = false;

    @Watch("includeClosedRejected")
    public async onSwitchChanged(val: boolean): Promise<void> {
        await this.loadApprouverRequests(val);
    }

    public headers = [
        { text: "", class: "nowrap", value: "snakeAppLogo", sortable: false },
        { text: "Application", class: "nowrap", cellClass: "nowrap", value: "snakeAppName", sortable: false },
        { text: "Status", value: "status", class: "nowrap" },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Requested on", value: "requestedOn", class: "nowrap", cellClass: "nowrap" },
        { text: "", value: "actions", sortable: false },
    ];

    public approverRequests: IRequestDatatable[] = [];

    async mounted(): Promise<void> {
        await this.loadApprouverRequests(false);
    }

    public async loadApprouverRequests(val: boolean): Promise<void>{
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadApproverRequests", val).then(async (data)=>{
                this.approverRequests = data;
                await this.$store.dispatch("app/loadSnakeApps", data.map(r => r.snakeId)).then((snakeApps)=>{
                    this.approverRequests.forEach(ar => {
                        const snakeApp = snakeApps.find(sa => sa.snakeId === ar.snakeId);
                        if(snakeApp){
                            ar.snakeAppName = snakeApp.general.name;
                            ar.snakeAppLogo = snakeApp.general.logo;
                            ar.snakeAppIsPlatform = snakeApp.general.isPlatform;
                        }
                    });
                });
            });
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }
}
