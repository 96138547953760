import Vue from "vue";
import { Component } from "vue-property-decorator";
import { affiliateRepository } from "@/repositories";
import { IAffiliate } from "@/models";
import { FileNames } from "@/enums";

@Component({
    name: "affiliate",
    metaInfo: {
        title: "Affiliate",
    },
})

export default class Affiliate extends Vue {

    public loading: boolean = false;
    public loadingButton: boolean = false;

    public headers = [
        { text: "Name", value: "name", class: "nowrap", cellClass: "fixedHeight", sortable: true },
    ];

    public search = "";

    public fields: IAffiliate[] = [];

    get itemsPerPage(): number{
        return this.$store.getters["app/itemsPerPage"];
    }

    async mounted(): Promise<void> {
        this.loading = true;
        affiliateRepository.getAllWithRequestsNumber().then(res=> {
            this.fields = res;
            this.loading = false;
        }).catch(error => {
            this.loading = false;
            this.$emit("error", error)
        });
    }

    public handleItemsPerPage(itemsPerPage: number): void{
        this.$store.dispatch("app/updateItemsPerPage", itemsPerPage);
    }

    public async exportData(): Promise<void> {
        try {
            this.loadingButton = true;
            await affiliateRepository.export("export").then(res=> {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', FileNames.Affiliate)
                document.body.appendChild(link);
                link.click();
                this.loadingButton = false;
            });
        }
        catch (error) {
            this.$emit("error", error);
        }
    }

}
