import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { userGroupRepository } from "@/repositories";
import { IUserGroup, IGetUserGroupParams, IGetUserGroupSearchForm } from "@/models";
import { FileNames } from "@/enums";

@Component({
    name: "userGroup",
    metaInfo: {
        title: "UserGroup",
    },
})

export default class UserGroup extends Vue {

    public loading: boolean = false;
    public loadingButton: boolean = false;

    public headers = [
        { text: "Name", value: "name", class: "nowrap", cellClass: "fixedHeight", sortable: true },
        { text: "Description", value: "description", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "Azure AD Id", value: "azureAdId", class: "nowrap", cellClass: "fixedHeight", sortable: false },
        { text: "On-Premises Sam Account Name", value: "onPremisesSamAccountName", sortable: false },
        { text: "On-Premises Domain Name", value: "onPremisesDomainName", class: "nowrap", cellClass: "fixedHeight", sortable: false },
    ];

    public search = "";

    public fields: IUserGroup[] = [];

    public totalFields = 0;

    public params: IGetUserGroupParams;

    public body: IGetUserGroupSearchForm;

    public options = {
        sortBy: ["name"] ,
        sortDesc: [false],
        page: 1,
        itemsPerPage: 10
    };

    @Watch('search')
    @Watch('options')
    onPropertyChanged() {
        if (this.search === "") {
            this.loadAll();    
        }
        else{
            this.loadFromSearch();
        }
        
    }
    loadFromSearch() {
        this.loading = true;
        this.body = {
            searchText: this.search,
            pageNumber: this.options.page, 
            pageSize: this.options.itemsPerPage, 
            sortBy: this.options.sortBy[0],
            sortDesc: this.options.sortDesc[0]
        };
        userGroupRepository.search(this.body).then(res=> {
            this.fields = res.items;
            this.totalFields = res.total;
            this.loading = false;
        }).catch (error => {
            this.loading = false;
            this.$emit("error", error);
        });
    }

    public loadAll() : void{
        this.loading = true;
        this.params = {
            pageNumber: this.options.page, 
            pageSize: this.options.itemsPerPage, 
            sortBy: this.options.sortBy[0],
            sortDesc: this.options.sortDesc[0]
        };
        userGroupRepository.getAllWithRequestsNumber(this.params).then(res=> {
            this.fields = res.items;
            this.totalFields = res.total;
            this.loading = false;
        }).catch (error => {
            this.loading = false;
            this.$emit("error", error);
        });
    }

    public async exportData(): Promise<void> {
        this.loadingButton = true;
        userGroupRepository.export("export").then(res=> {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', FileNames.UserGroup)
            document.body.appendChild(link);
            link.click();
            this.loadingButton = false;
        }).catch (error => {
            this.$emit("error", error);
        });   
    }
}
