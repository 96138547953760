import { Vue, Component } from "vue-property-decorator";
import { ChartBar, ChartPie } from "@/components";
import { statisticsRepository } from "@/repositories/StatisticsRepository";
import { IStatsClassification, IStatsField, IStatsStatus, IStatsStatusClassification } from "@/models";
import { FieldClassifications } from "@/enums";
import { Convertors } from "@/helpers";

@Component({
    components: {
        ChartBar,
        ChartPie,
    },
    metaInfo: {
        title: "Statistics",
    },
})

export default class Statistics extends Vue {
    public loading: boolean = false;
    public rFields: IStatsField[] = [];
    public rStatus: IStatsStatus[] = [];
    public rClassifications: IStatsClassification[] = [];
    public rStatusClassifications: IStatsStatusClassification[] = [];
    public cFields: IStatsField[] = [];
    public cClassifications: IStatsClassification[] = [];

    get rStatsFields(): Object {
        return {
            labels: this.rFields.map(f => f.name),
            datasets: [{
                label: "# of fields requested",
                data: this.rFields.map(f => f.nbItems),
                backgroundColor: this.rFields.map(f => Convertors.convertClassificationToColor(f.classification)),
                borderColor: this.rFields.map(f => Convertors.convertClassificationToColor(f.classification, 1)),
                borderWidth: 1,
            }]
        };
    }

    get rStatsStatus(): Object {
        return {
            labels: this.rStatus.map(f => Convertors.convertRequestStatusToLabel(f.status)),
            datasets: [{
                label: "# of requests",
                data: this.rStatus.map(f => f.nbItems),
                backgroundColor: this.rStatus.map(f => Convertors.convertStatusToColor(f.status)),
                borderColor: this.rStatus.map(f => Convertors.convertStatusToColor(f.status, 1)),
                borderWidth: 1,
            }]
        };
    }

    get rStatsClassifications(): Object {
        return {
            labels: this.rClassifications.map(c => c.classification),
            datasets: [{
                label: "# of classifications requested",
                data: this.rClassifications.map(c => c.nbItems),
                backgroundColor: this.rClassifications.map(c => Convertors.convertClassificationToColor(c.classification)),
                borderColor: this.rClassifications.map(c => Convertors.convertClassificationToColor(c.classification, 1)),
                borderWidth: 1,
            }]
        };
    }

    get cStatsFields(): Object {
        return {
            labels: this.cFields.map(f => f.name),
            datasets: [{
                label: "# of fields requested",
                data: this.cFields.map(f => f.nbItems),
                backgroundColor: this.cFields.map(f => Convertors.convertClassificationToColor(f.classification)),
                borderColor: this.cFields.map(f => Convertors.convertClassificationToColor(f.classification, 1)),
                borderWidth: 1,
            }]
        };
    }



    get cStatsClassifications(): Object {
        return {
            labels: this.cClassifications.map(c => c.classification),
            datasets: [{
                label: "# of classifications requested",
                data: this.cClassifications.map(c => c.nbItems),
                backgroundColor: this.cClassifications.map(c => Convertors.convertClassificationToColor(c.classification)),
                borderColor: this.cClassifications.map(c => Convertors.convertClassificationToColor(c.classification, 1)),
                borderWidth: 1,
            }]
        };
    }


    get rStatsStatusClassifications(): Object {
        let fieldsDataset = {
            greenFields: {
                data: [],
                backgroundColor: Convertors.convertClassificationToColor(FieldClassifications.Green),
                borderColor: Convertors.convertClassificationToColor(FieldClassifications.Green, 1),
                borderWidth: 1,
                label: "Green fields",
            },
            orangeFields: {
                data: [],
                backgroundColor: Convertors.convertClassificationToColor(FieldClassifications.Orange),
                borderColor: Convertors.convertClassificationToColor(FieldClassifications.Orange, 1),
                borderWidth: 1,
                label: "Orange fields",
            },
            redFields: {
                data: [],
                backgroundColor: Convertors.convertClassificationToColor(FieldClassifications.Red),
                borderColor: Convertors.convertClassificationToColor(FieldClassifications.Red, 1),
                borderWidth: 1,
                label: "Red fields",
            },
            newFields: {
                data: [],
                backgroundColor: Convertors.convertClassificationToColor(FieldClassifications.NewField),
                borderColor: Convertors.convertClassificationToColor(FieldClassifications.NewField, 1),
                borderWidth: 1,
                label: "New fields",
            },
        };

        this.rStatusClassifications.forEach(item => {
            const greenValues = (item.statsClassifications.filter(c => c.classification === FieldClassifications.Green));
            if(greenValues.length > 0){
                fieldsDataset.greenFields.data.push(greenValues[0].nbItems);
            } else {
                fieldsDataset.greenFields.data.push(0);
            }

            const orangeValues = (item.statsClassifications.filter(c => c.classification === FieldClassifications.Orange));
            if(orangeValues.length > 0){
                fieldsDataset.orangeFields.data.push(orangeValues[0].nbItems);
            } else {
                fieldsDataset.orangeFields.data.push(0);
            }

            const redValues = (item.statsClassifications.filter(c => c.classification === FieldClassifications.Red));
            if(redValues.length > 0){
                fieldsDataset.redFields.data.push(redValues[0].nbItems);
            } else {
                fieldsDataset.redFields.data.push(0);
            }

            const newFieldsValues = (item.statsClassifications.filter(c => c.classification === FieldClassifications.NewField));
            if(newFieldsValues.length > 0){
                fieldsDataset.newFields.data.push(newFieldsValues[0].nbItems);
            } else {
                fieldsDataset.newFields.data.push(0);
            }
        });

        return {
            labels: this.rStatusClassifications.map(sc => Convertors.convertRequestStatusToLabel(sc.status)),
            
            datasets: [
                fieldsDataset.greenFields,
                fieldsDataset.orangeFields,
                fieldsDataset.redFields,
                fieldsDataset.newFields,
            ]
        };
    }

    async mounted(): Promise<void> {
        this.loading = true;
        try{
            this.rFields = await statisticsRepository.requestsField({});
            this.rStatus = await statisticsRepository.requestsStatus({});
            this.rClassifications = await statisticsRepository.requestsClassification({});
            this.rStatusClassifications = await statisticsRepository.requestsStatusClassification({});

            this.cFields = await statisticsRepository.clientsField({});
            this.cClassifications = await statisticsRepository.clientsClassification({});
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }
}
